import * as React from "react";
import tw from "twin.macro";

function CampaignLogo({ onPrimary = false, ...props }) {
  return (
    <svg
      width="218"
      height="29"
      viewBox="0 0 218 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M32.9523 3.99065C32.7637 3.27674 32.389 2.62555 31.8664 2.10343C31.3438 1.58132 30.6921 1.20693 29.9776 1.01842C27.3726 0.306152 16.8227 0.306152 16.8227 0.306152C16.8227 0.306152 6.29946 0.306152 3.69446 1.01842C2.98091 1.21403 2.33 1.59042 1.80475 2.11113C1.27951 2.63185 0.897733 3.27925 0.69639 3.99065C0.00683332 6.59343 0.00683594 12.0719 0.00683594 12.0719C0.00683594 12.0719 0.00683332 17.5504 0.69639 20.1798C0.900645 20.8897 1.2834 21.5356 1.8082 22.0558C2.333 22.5761 2.98237 22.9535 3.69446 23.152C6.29946 23.8643 16.8227 23.8643 16.8227 23.8643C16.8227 23.8643 27.3726 23.8643 29.9776 23.152C30.6901 22.9595 31.3397 22.5837 31.8616 22.0623C32.3836 21.5408 32.7596 20.8917 32.9523 20.1798C33.6652 17.5504 33.6652 12.0719 33.6652 12.0719C33.6652 12.0719 33.6585 6.59343 32.9523 3.99065ZM13.4748 17.121V7.0361L22.2026 12.0719L13.4748 17.121Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          d="M42.9458 12.9273C42.9458 5.09901 46.8766 1.00513 54.6783 1.00513C57.4515 1.03533 60.1664 1.80397 62.5432 3.2318L59.4385 7.80164C58.0398 6.92431 56.4263 6.44873 54.7749 6.42702C51.6302 6.42702 49.6648 8.62041 49.6648 12.7775C49.6648 16.8381 51.6635 18.9683 54.8748 18.9683C55.7197 18.9771 56.5571 18.8093 57.3332 18.4757V15.4802H53.8321L55.078 11.1533H62.813V22.3865C60.6811 23.7179 57.7663 24.4168 54.2585 24.4168C46.82 24.4201 42.9458 20.4893 42.9458 12.9273Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          d="M66.647 12.7309C66.647 5.2621 70.6444 1.00513 77.6599 1.00513C84.6754 1.00513 88.6695 5.2621 88.6695 12.7309C88.6695 20.1998 84.6721 24.4201 77.6599 24.4201C70.6477 24.4201 66.647 20.1632 66.647 12.7309ZM81.7873 12.7309C81.7873 8.73691 80.3782 6.64004 77.6599 6.64004C74.9417 6.64004 73.5292 8.73358 73.5292 12.7309C73.5292 16.7283 74.925 18.7852 77.6599 18.7852C80.3948 18.7852 81.7873 16.725 81.7873 12.7309V12.7309Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          d="M108.664 23.8643H93.127V1.56433H108.664V6.67004H100.003V9.97842H107.378L106.132 14.7613H100.009V18.7553H108.67L108.664 23.8643Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          d="M112.071 21.8341L114.003 16.5087C116.135 17.9499 118.134 18.802 119.903 18.802C121.279 18.802 122.428 18.5057 122.428 17.2609C122.428 16.3456 121.379 15.4603 118.167 14.1522C114.956 12.8442 112.758 11.0069 112.758 7.37237C112.758 3.34506 116.089 1.01853 120.886 1.01853C123.525 1.00902 126.117 1.71039 128.391 3.04883L126.262 8.04137C125.083 7.45225 123.377 6.66676 121.312 6.66676C119.903 6.66676 119.347 6.99959 119.347 7.71186C119.347 8.5972 119.836 8.82686 123.084 10.2348C127.771 12.2651 129.344 14.6548 129.344 17.6037C129.344 22.4166 125.836 24.4136 120.852 24.4136C117.736 24.4284 114.684 23.5317 112.071 21.8341V21.8341Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.7 3.62229C138.898 5.54037 137.915 8.3794 137.915 12.0553C137.915 15.6666 138.875 18.4749 140.634 20.3895C142.39 22.3008 144.981 23.3644 148.336 23.4117L148.424 23.413L156.462 28.12L159.211 24.2872L154.706 21.9179L155.152 21.6048C157.793 19.7547 159.271 16.5323 159.271 12.0553C159.271 8.37933 158.288 5.54029 156.487 3.62223C154.689 1.70727 152.034 0.665672 148.595 0.665672C145.155 0.665672 142.499 1.70728 140.7 3.62229ZM140.214 3.16673C142.168 1.08688 145.018 0 148.595 0C152.171 0 155.02 1.08689 156.973 3.16679C158.923 5.2436 159.937 8.26579 159.937 12.0553C159.937 16.4407 158.558 19.7806 155.966 21.8287L160.198 24.0539L156.65 29.0014L148.238 24.0758C144.786 24.0092 142.033 22.8975 140.143 20.8397C138.239 18.7678 137.249 15.7797 137.249 12.0553C137.249 8.26572 138.264 5.24352 140.214 3.16673ZM145.234 7.32433C145.995 6.2009 147.136 5.63158 148.595 5.63158C150.053 5.63158 151.194 6.20192 151.954 7.32578C152.699 8.42839 153.055 10.0286 153.055 12.0553C153.055 14.082 152.698 15.6762 151.952 16.7725C151.191 17.8899 150.05 18.4524 148.595 18.4524C147.139 18.4524 145.998 17.891 145.236 16.7739C144.488 15.678 144.131 14.0838 144.131 12.0553C144.131 10.0269 144.487 8.42656 145.234 7.32433ZM145.786 7.69739C145.147 8.64127 144.797 10.0864 144.797 12.0553C144.797 14.0242 145.147 15.4621 145.786 16.399C146.411 17.3148 147.335 17.7868 148.595 17.7868C149.854 17.7868 150.777 17.3141 151.401 16.398C152.039 15.4606 152.389 14.0226 152.389 12.0553C152.389 10.088 152.04 8.64277 151.402 7.69844C150.777 6.77535 149.854 6.29726 148.595 6.29726C147.335 6.29726 146.411 6.77471 145.786 7.69739Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.315 0.555786H170.863V13.9857C170.863 15.3097 171.128 16.2579 171.59 16.8682C172.04 17.4626 172.711 17.78 173.642 17.78C174.572 17.78 175.235 17.463 175.677 16.8701C176.133 16.2599 176.39 15.3114 176.39 13.9857V0.555786H183.938V13.9857C183.938 17.2523 183.071 19.7908 181.315 21.5104C179.561 23.2289 176.972 24.0773 173.642 24.0773C170.311 24.0773 167.716 23.2316 165.954 21.5148C164.19 19.7966 163.315 17.2579 163.315 13.9857V0.555786ZM163.981 1.22146V13.9857C163.981 17.1439 164.825 19.4846 166.419 21.0382C168.015 22.5932 170.416 23.4116 173.642 23.4116C176.867 23.4116 179.26 22.5908 180.849 21.0351C182.436 19.4804 183.272 17.1396 183.272 13.9857V1.22146H177.056V13.9857C177.056 15.3793 176.789 16.4944 176.211 17.268C175.621 18.0588 174.743 18.4457 173.642 18.4457C172.541 18.4457 171.656 18.0592 171.058 17.2698C170.473 16.4964 170.197 15.3811 170.197 13.9857V1.22146H163.981Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.243 0.555786H195.791V23.5215H188.243V0.555786ZM188.909 1.22146V22.8558H195.125V1.22146H188.909Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M199.615 0.555786H217.999L209.388 17.0911H217.794V23.5215H198.924L207.495 6.98285H199.615V0.555786ZM200.281 1.22146V6.31718H208.591L200.02 22.8558H217.127V17.7568H208.29L216.902 1.22146H200.281Z"
          css={[onPrimary ? tw`fill-secondary` : tw`fill-primary`]}
        />
      </g>
    </svg>
  );
}

export default CampaignLogo;
