import * as React from "react";
import CampaignLogo from "./CampaignLogo";
import Close from "./icons/Close";
import "twin.macro";

export default function MessageScreen({
  onClose,
  children,
}: {
  onClose?: () => void;
  children: React.ReactNode;
}) {
  return (
    <div tw="absolute w-full h-full bg-primary flex flex-col justify-center items-center">
      <CampaignLogo tw="absolute left-2 top-2" onPrimary />
      {!!onClose && (
        <div
          tw="absolute right-0 top-0 p-2 text-secondary cursor-pointer"
          onClick={onClose}
        >
          <Close />
        </div>
      )}
      {children}
    </div>
  );
}

MessageScreen.Text = function Text({
  children,
  ...props
}: {
  children: React.ReactNode;
}) {
  return (
    <p tw="max-w-[440px] text-default font-32 text-center w-[80%]" {...props}>
      {children}
    </p>
  );
};
