import * as React from "react";
import { GlobalStyles } from "twin.macro";
import { Global, css } from "@emotion/react";
import useOrientation from "../hooks/useOrientation";

const fontStyles = css`
  @font-face {
    font-family: "YouTubeSans";
    src: url("/fonts/YouTubeSans-Regular.woff") format("woff"),
      url("/fonts/YouTubeSans-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: "YouTubeSans";
    src: url("/fonts/YouTubeSans-SemiBold.woff") format("woff"),
      url("/fonts/YouTubeSans-SemiBold.woff2") format("woff2");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: "YouTubeSans";
    src: url("/fonts/YouTubeSans-Bold.woff") format("woff"),
      url("/fonts/YouTubeSans-Bold.woff2") format("woff2");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`;

export default function Layout({
  children,
  ...props
}: {
  children?: React.ReactNode;
}) {
  const orientation = useOrientation();
  return (
    <main tw="relative w-full h-full overflow-hidden">
      <GlobalStyles />
      <Global styles={fontStyles} />
      <div
        css={[
          css`
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          `,
          orientation === "portrait"
            ? css`
                left: 0;
                top: env(safe-area-inset-top);
                right: 0;
                bottom: env(safe-area-inset-bottom);
              `
            : css`
                left: env(safe-area-inset-left);
                top: env(safe-area-inset-top);
                right: env(safe-area-inset-right);
                bottom: env(safe-area-inset-bottom);
              `,
        ]}
      >
        {children}
      </div>
    </main>
  );
}
