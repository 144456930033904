import * as React from "react";
/** @ts-ignore */
import useScreenOrientation from "react-hook-screen-orientation";

export default function useOrientation() {
  const [orientation, setOrientation] = React.useState("landscape");

  function checkOrientation() {
    setOrientation(
      window.innerWidth > window.innerHeight ? "landscape" : "portrait",
    );
  }

  React.useEffect(() => {
    checkOrientation();
    window.addEventListener("orientationchange", checkOrientation);
    window.addEventListener("resize", checkOrientation);
    return () => {
      window.removeEventListener("orientationchange", checkOrientation);
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);
  return orientation;
}
